@tailwind base;
@tailwind utilities;
@tailwind components;

@import "../public/assets/fontawesome/css/all.css";

.custom-number-input {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
}

.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

@media only screen and (max-width: 768px) {
    input[type="text"], 
    input[type="number"], 
    input[type="email"], 
    input[type="password"], 
    textarea {
      font-size: 16px; /* Ensure a minimum font size of 16px to prevent auto-zoom */
    }
  }
